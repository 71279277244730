<template>
  <AuthLayout>
    <h1 class="mb-2">Reset your Password</h1>

    <span style="color: #555555">
      Your new password must be different from previous used passwords.
    </span>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="resetPassword">
        <div class="my-3">
          <Input
            type="password"
            placeholder="Enter New Password"
            v-model="formData.password"
            name="Password"
            rules="required|between:6,21"
            autocomplete="current-password"
            passwordToggleType="icon"
          />
        </div>
        <div class="my-3">
          <Input
            type="password"
            placeholder="Confirm Password"
            v-model="formData.confirm_password"
            name="Password"
            rules="required|between:6,21"
            autocomplete="current-password"
            passwordToggleType="icon"
          />
        </div>

        <Button
          type="submit"
          :disabled="
            invalid ||
            loading ||
            formData.confirm_password !== formData.password ||
            !formData.confirm_password ||
            !formData.password
          "
          :loading="loading"
          class="mb-2"
          theme="blue"
        >
          Reset Password
        </Button>
      </form>

      <NewModal v-model="showModal" target="modal-portal">
        <div class="flex flex__center">
          <div class="mb-3">
            <img
              src="../../../assets/icons/success-icon.svg"
              alt="success-icon"
            />
          </div>
        </div>

        <div class="text-center mb-2">
          <span class="text-center text__light-grey">
            Your password has been reset successfully!
          </span>
        </div>
        <div class="flex flex__center">
          <Button
            theme="blue"
            class="w-27"
            @click="$router.push({ name: 'login' })"
          >
            Login using new password
          </Button>
        </div>
      </NewModal>
    </ValidationObserver>
  </AuthLayout>
</template>

<script>
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import AuthLayout from "../../../layouts/AuthLayout";
import API from "../../../const";
import { mapActions } from "vuex";
import authService from "../../../services/api/authService";

export default {
  name: "ResetPassword",
  props: ["token"],
  components: {
    AuthLayout,
    Input,
    Button,
    Modal
  },
  data() {
    return {
      loading: false,
      showModal: false,
      formData: {
        password: "",
        confirm_password: ""
      }
    };
  },
  methods: {
    ...mapActions({
      changePassword: "apimodel/makeRequest"
    }),
    resetPassword() {
      this.loading = true;
      authService
        .resetPassword(this.formData, this.token)
        .then((res) => {
          this.$handleSuccess(res);
          this.showModal = !this.showModal;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });

      //   try {
      //     const { data } = await this.changePassword({
      //       type: "put",
      //       url: API.CHANGE_PASSWORD,
      //       data: {
      //         token,
      //         password: this.formData.password
      //       }
      //     });

      //     if (!data.error) {
      //       this.$notify({
      //         text: "Password updated successfully",
      //         title: "Success",
      //         type: "success"
      //       });

      //       this.showModal = !this.showModal;

      //       return;
      //     }

      //     this.$notify({
      //       text: error.message,
      //       title: "Error",
      //       type: "error"
      //     });
      //   } catch (error) {
      //     return error;
      //   } finally {
      //     this.loading = false;
      //   }
    }
  }
};
</script>

<style scoped></style>
